import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getCurrentUser } from '../api';
import { fetchUserData } from './actions';
import { UserData } from './models';
import { notificationsManager } from '../Notifications';

function* fetchUserDataSaga() {
  try {
    const { data }: AxiosResponse<UserData> = yield call(getCurrentUser);

    yield put(fetchUserData.success(data));
  } catch (e) {
    yield put(fetchUserData.failure(e));
  }
}

export function* watchFetchUserData() {
  yield takeLatest(fetchUserData.request, fetchUserDataSaga);
}
