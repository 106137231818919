import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { TonCookieSelect } from 'src/pages/Mandates/models';
import { CookieConsentStyle } from './CookieConsent.styles';
import { Link } from 'src/components/Text';
import { t } from 'i18next';
import { onHandleKeyDown } from 'src/utils/onHandleKeyDown';

interface ICookieConsent {
  onClick: TonCookieSelect;
  value?: boolean;
}

const CookieConsent = ({ onClick, value = false }: ICookieConsent) => {
  const [checkedBox, setCheckedBox] = useState(value);
  const handleChange = () => setCheckedBox(prev => !prev);

  return (
    <FormGroup row={true}>
      <FormControlLabel
        label={
          <Trans
            i18nKey="SignatoryPersonalDataConsent"
            components={[
              <Link
                href={t('PrivacyNoticePrivateLink')}
                target="_blank"
                rel="noreferrer noopener"
                colorType="secondary"
              >
                Text
              </Link>,
              <Link
                href={t('PrivacyNoticeBusinessLink')}
                target="_blank"
                rel="noreferrer noopener"
                colorType="secondary"
              >
                Text
              </Link>,
            ]}
          />
        }
        control={
          <Checkbox
            onChange={handleChange}
            id="CookieConsent"
            onKeyPress={event => onHandleKeyDown(event, handleChange)}
            onClick={onClick}
            checked={checkedBox}
          />
        }
      />
      <CookieConsentStyle />
    </FormGroup>
  );
};

export default CookieConsent;
