import React, { HTMLAttributes, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TNavigationLinks } from 'src/models';
import { CollapseStyled, Item, Menu, ItemIcon, ItemText } from './styles';
import { renderSigningCasesCounter } from '../NavList';
import { ExpandMore, ExpandLess } from 'src/components/Icons';
import { ItemElement } from './ItemElement';
import { lightWhite } from 'src/styles/colors';
import { LanguageMenuLink, isHomeRoute } from 'src/routes';

interface Props extends HTMLAttributes<HTMLUListElement> {
  awaitingSigningCasesCount: number;
  isHamburgerMenuOpen: boolean;
  links?: TNavigationLinks;
  languageCode: string;
  languageMenuLinks: LanguageMenuLink[];
  toggleHamburgerMenu: () => void;
}

export const DropdownMenu = ({
  isHamburgerMenuOpen = false,
  links = [],
  toggleHamburgerMenu,
  awaitingSigningCasesCount,
  languageCode,
  languageMenuLinks,
  ...rest
}: Props) => {
  const [isLanguageMenuOpened, openLanguageMenu] = useState(false);

  const handleOnClickChange = (
    event: React.KeyboardEvent<HTMLLIElement>,
    option: string
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      return option;
    }
  };

  return (
    <Menu show={isHamburgerMenuOpen} {...rest} role="menu">
      {links.map((link, index) => (
        <Item key={index} role="none">
          <ItemElement
            onKeyDown={e => handleOnClickChange(e, link.to)}
            tabIndex={0}
            to={link.to}
            role="menuitem"
            exact
            onClick={toggleHamburgerMenu}
            as={NavLink}
            activeStyle={{ backgroundColor: lightWhite }}
          >
            {link.title}
            {isHomeRoute(link.to) &&
              renderSigningCasesCounter(awaitingSigningCasesCount)}
          </ItemElement>
        </Item>
      ))}
      {languageMenuLinks.length > 1 && (
        <Item role="none">
          <ItemElement
            tabIndex={0}
            as="button"
            role="menuitem"
            onKeyDown={e =>
              handleOnClickChange(e, languageMenuLinks[0].languageCode)
            }
            onClick={() => openLanguageMenu(!isLanguageMenuOpened)}
          >
            <ItemText>{languageCode.toUpperCase()}</ItemText>
            <ItemIcon>
              {isLanguageMenuOpened ? <ExpandLess /> : <ExpandMore />}
            </ItemIcon>
          </ItemElement>
        </Item>
      )}
      <CollapseStyled
        in={isLanguageMenuOpened && isHamburgerMenuOpen}
        component={props => <ul {...props} role="menu" />}
      >
        {languageMenuLinks
          .filter(menuLink => menuLink.languageCode !== languageCode)
          .map(menuLink => (
            <Item key={menuLink.languageCode} role="none">
              <ItemElement
                tabIndex={0}
                as="a"
                href={menuLink.path}
                role="menuitem"
                onKeyDown={e => handleOnClickChange(e, menuLink.path)}
              >
                {menuLink.languageCode.toUpperCase()}
              </ItemElement>
            </Item>
          ))}
      </CollapseStyled>
    </Menu>
  );
};

export default DropdownMenu;
