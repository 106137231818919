import { combineReducers } from 'redux';
import { error, isLoading, success } from '../Async';
import { fetchUserData } from './actions';
import { UserData } from './models';

const userData = combineReducers({
  data: success<Partial<UserData>>({}, fetchUserData),
  error: error(fetchUserData),
  isLoading: isLoading(fetchUserData),
});

export default userData;

type State = ReturnType<typeof userData>;

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getIsOnboarded = (state: State) =>
  (state.data && state.data.isOnboarded) || false;

export const getId = (state: State) => state.data && state.data.userId;
