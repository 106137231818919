import { t } from 'i18next';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import countries, { fromCountries } from './Countries';
import { fromIdentity, identityReducer } from './Identity';
import {
  fromMandate,
  fromMandates,
  mandate,
  mandates,
  mandateRevocation,
  fromMandateRevocation,
} from './Mandates';
import { customerCreation, fromCustomerCreation } from './CustomerCreation';
import newMandateForm, { fromNewMandateForm } from './Mandates/New';
import { fromNotifications, notificationsService } from './Notifications';
import notifications from './Notifications/reducer';
import productAreas, { fromProductAreas } from './ProductAreas';
import signing, * as fromSigning from './Signing';
import signingCaseDetails from './SigningCaseDetails/reducers';
import signingCasesList from './SigningCasesList/reducers';
import signingRulesAndSignatories from './SigningRulesAndSignatories/reducer';
import { fromUserData, userData } from './UserData';
import { archiveFilter, fromArchiveFilter } from '../AgreementsCard';
import { areDocumentsSeen } from 'src/utils/AgreementDocument';
import * as fromSigningCaseDetails from 'src/state/SigningCaseDetails/selectors';
import userSession, { fromUserSession } from 'src/state/UserSession';

const rootReducer = combineReducers<any, any>({
  archiveFilter,
  countries,
  customerCreation,
  identity: identityReducer,
  mandateNew: newMandateForm,
  notifications,
  productAreas,
  signing,
  signingCaseDetails,
  signingCasesList,
  signingMandate: combineReducers({
    details: mandate,
    mandateRevocation,
  }),
  signingMandates: mandates,
  signingRulesAndSignatories,
  userData,
  userSession,
});

export default rootReducer;

type State = ReturnType<typeof rootReducer>;

const getArchiveFilter = (state: State) => state.archiveFilter;

export const getArchiveFilterStatus = (state: State) =>
  fromArchiveFilter.getStatus(getArchiveFilter(state));

export const getArchiveFilterDate = (state: State) =>
  fromArchiveFilter.getDate(getArchiveFilter(state));

export const getArchiveFilterTimeframe = (state: State) =>
  fromArchiveFilter.getTimeframe(getArchiveFilter(state));

export const getArchiveFilterIsDateValid = (state: State) =>
  fromArchiveFilter.getIsDateValid(getArchiveFilter(state));

export const getCountries = (state: State) => state.countries;

export const getCountriesList = (state: State) =>
  fromCountries.getList(getCountries(state));

export const getIsCountriesLoading = (state: State) =>
  fromCountries.getIsLoading(getCountries(state));

export const getCountryOptions = createSelector(
  getCountriesList,
  list =>
    list.map(country => ({ value: country.twoLetterCode, label: country.name }))
);

export const getIdentity = (state: State) => state.identity;

export const getIsIdentityLoading = (state: State) =>
  fromIdentity.getIsIdentityLoading(getIdentity(state));

export const getIdentityExternalId = (state: State) =>
  fromIdentity.getExternalId(getIdentity(state));

export const getIdentityError = (state: State) =>
  fromIdentity.getError(getIdentity(state));

export const getCompanyRoles = (state: State) =>
  fromIdentity.getCompanyRoles(getIdentity(state));

export const is1CompanyRole = (state: State) => {
  const roles = getCompanyRoles(state);
  return roles ? roles.length === 1 : false;
};

export const getCompanyRole = (state: State, id: string) =>
  (getCompanyRoles(state) || []).find(x => x.companyId === id);

export const getProductAreas = (state: State) => state.productAreas;

export const getProductAreasList = (state: State) =>
  fromProductAreas.getProductAreasList(getProductAreas(state));

export const haveProductAreas = (state: State) => {
  const list = getProductAreasList(state);
  return list ? list.length > 0 : false;
};

export const is1ProductArea = (state: State) => {
  const list = getProductAreasList(state);
  return list ? list.length === 1 : false;
};

export const getIsProductAreasLoading = (state: State) =>
  fromProductAreas.getIsProductAreasLoading(getProductAreas(state));

export const isNewMandateFormLoading = (state: State) =>
  getIsProductAreasLoading(state) || getIsIdentityLoading(state);

export const getNewMandateForm = (state: State) => state.mandateNew;

export const getSelectedCompanyId = (state: State) =>
  fromNewMandateForm.getCompany(getNewMandateForm(state));

export const getCreateMandate = (state: State) =>
  fromNewMandateForm.getCreateMandate(getNewMandateForm(state));

export const getNotifications = (state: State) => state.notifications;

export const getNotificationsTypes = (state: State) => {
  return fromNotifications.getNotificationsTypes(getNotifications(state));
};

export const getNewMandateExpirationDate = (state: State) => {
  return getNewMandateForm(state).expirationDate;
};

export const getIsNewMandateExpirationDateEnabled = (state: State) => {
  return getNewMandateForm(state).isExpirationDateEnabled;
};

export const getIsNewMandateExpirationDateFormatValid = (state: State) => {
  return getNewMandateForm(state).isExpirationDateFormatValid;
};

export const getSelectedCompanyFormatted = (state: State) => {
  const id = getSelectedCompanyId(state);
  const role = getCompanyRole(state, id);

  return role ? `${role.companyName} [${role.companyId}]` : '';
};

export const getSignatoriesFormatted = (state: State) =>
  getSignatories(state).map(
    signatory => `${signatory.fullName} [${signatory.externalId}]`
  );

export const getMandatesNewFormatted = (state: State) => {
  const form = getNewMandateForm(state);

  return {
    ...form,
    signatories: fromNewMandateForm.getSignatoriesFormatted(form),
  };
};

export const getSelectedProductAreas = (state: State) =>
  getNewMandateForm(state).productAreas;

export const haveSelectedProductAreas = (state: State) =>
  Object.values(getSelectedProductAreas(state)).filter(x => x === true).length >
  0;

export const getProductAreasNames = (state: State) => {
  const list = getProductAreasList(state);

  if (!list) {
    return [];
  }

  return Object.entries(getSelectedProductAreas(state)).reduce(
    (acc, [key, isSelected]) => {
      if (isSelected && list[key]) {
        return [...acc, list[key].name];
      }

      return acc;
    },
    [] as any
  );
};

export const getProductAreasCode = (state: State) => {
  const list = getProductAreasList(state);

  if (!list) {
    return [];
  }

  return Object.entries(getSelectedProductAreas(state)).reduce(
    (acc, [key, isSelected]) => {
      if (isSelected && list[key]) {
        return [...acc, list[key].code];
      }

      return acc;
    },
    [] as any
  );
};

export const getMandateTitle = (state: State) => {
  const names = getProductAreasNames(state);

  return names.length === 0
    ? ''
    : names.length === 1
    ? names[0]
    : names.length === 2
    ? names.join(t('ProductSeparator')) + ' ' + t('SinglyPlaceholder')
    : t('DefaultName') + ' ' + t('SinglyPlaceholder');
};

export const getSignatories = (state: State) =>
  fromNewMandateForm.getSignatories(getNewMandateForm(state));

export const getValidSignatories = (state: State) =>
  fromNewMandateForm.getValidSignatories(getNewMandateForm(state));

export const areSignatoriesValid = (state: State) =>
  fromNewMandateForm.areSignatoriesValid(getNewMandateForm(state));

export const getRequiredSignatories = (state: State) =>
  fromNewMandateForm.getRequiredSignatories(getNewMandateForm(state));

export const getNewMandateFormData = (state: State) => {
  const mandateExpirationDate = getNewMandateExpirationDate(state);

  const year = mandateExpirationDate && mandateExpirationDate.getFullYear();
  const month =
    mandateExpirationDate &&
    `${mandateExpirationDate.getMonth() + 1}`.padStart(2, '0');
  const day =
    mandateExpirationDate &&
    `${mandateExpirationDate.getDate()}`.padStart(2, '0');

  return {
    companyId: getSelectedCompanyId(state),
    mandateExpirationDate: mandateExpirationDate
      ? `${year}-${month}-${day}Z`
      : null,
    mandateLimits: getProductAreasCode(state).map(s => ({ productArea: s })),
    mandatedSignatories: getSignatories(state).map(s => ({
      externalId: s.externalId,
      fullName: s.fullName,
    })),
    requiredMandatedSignatoriesCount: Number(getRequiredSignatories(state)),
    signingCaseName: t('MandateSigningCaseName'),
  };
};

export const haveErrorNotifications = (state: State) =>
  Object.values(getNotifications(state)).some((x: any) =>
    notificationsService.isErrorNotification(x.type)
  );

export const getMandatesData = (state: State) => state.signingMandates;

export const getMandates = (state: State) => {
  return fromMandates.getMandates(getMandatesData(state));
};

export const getIsMandatesLoading = (state: State) => {
  return (
    fromMandates.getIsLoading(getMandatesData(state)) ||
    getIsMandateRevoking(state)
  );
};

export const getMandateData = (state: State) => state.signingMandate;

export const getMandateDetails = (state: State) =>
  getMandateData(state).details;

export const getMandate = (state: State, id: string) => {
  const mandate = fromMandate.getMandate(getMandateDetails(state));

  return mandate && mandate.id === id
    ? mandate
    : fromMandates.getMandate(getMandatesData(state), id);
};

export const getMandateRevocation = (state: State) =>
  getMandateData(state).mandateRevocation;

export const getIsMandateRevoking = (state: State) =>
  fromMandateRevocation.isRevoking(getMandateRevocation(state));

export const getIsMandateRevoked = (state: State) =>
  fromMandateRevocation.isRevoked(getMandateRevocation(state));

export const getIsMandateLoading = (state: State) => {
  return fromMandate.getIsLoading(getMandateDetails(state));
};

const getUserData = (state: State) => state.userData;

export const getIsUserDataLoading = (state: State) => {
  return fromUserData.getIsLoading(getUserData(state));
};

export const getUserId = (state: State) => {
  return fromUserData.getId(getUserData(state));
};

export const getIsUserOnboarded = (state: State) => {
  return fromUserData.getIsOnboarded(getUserData(state));
};

type GetUserDataError = (
  state: State
) => ReturnType<typeof fromUserData.getError>;
export const getUserDataError: GetUserDataError = state => {
  return fromUserData.getError(getUserData(state));
};

export const getCustomerCreation = (state: State) => state.customerCreation;

export const getShouldOnboardCustomer = (state: State) => {
  return fromCustomerCreation.getShouldOnboardCustomer(
    getCustomerCreation(state)
  );
};

export const isCustomerCreated = (state: State) => {
  return fromCustomerCreation.getIsCreated(getCustomerCreation(state));
};

export const isCreatingCustomer = (state: State) => {
  return fromCustomerCreation.getIsLoading(getCustomerCreation(state));
};

export const getCustomerCreationError = (state: State) => {
  return fromCustomerCreation.getError(getCustomerCreation(state));
};

export const getIsUserLoaded = (state: State) => {
  return (
    !getCustomerCreationError(state) &&
    !isCreatingCustomer(state) &&
    !getUserDataError(state) &&
    !getIsUserDataLoading(state)
  );
};

export const getSigning = (state: State) => state.signing;

export const getSignature = (state: State) =>
  fromSigning.getSignature(getSigning(state));

export const getIsSignatureSuccessful = (state: State) =>
  fromSigning.getIsSignatureSuccessful(getSigning(state));

export const getIsSignatureCreated = (state: State) =>
  fromSigning.getIsSignatureCreated(getSigning(state));

export const getCanSign = (state: State, contractingPartyId): boolean => {
  const contractingParty =
    fromSigningCaseDetails.getContractingParty(state, contractingPartyId) || {};
  const documents = contractingParty.documents || [];
  return (
    areDocumentsSeen(documents) &&
    getUserData(state)
  );
};

export const isSigningCaseReadyToSign = (state: State, id: string) => {
  return fromSigningCaseDetails.isSigningCaseReadyToSign(state, id);
};

export const getUserSession = (state: State) => {
  return state.userSession;
};

export const getCurrentDate = (state: State) => {
  return fromUserSession.getCurrentDate(getUserSession(state));
};

export const getUserSessionExpirationDate = (state: State) => {
  return fromUserSession.getUserSessionExpirationDate(getUserSession(state));
};
