import Grid from '@material-ui/core/Grid';
import React, { useEffect, useRef, useState } from 'react';
import { IProductArea } from 'src/models';
import { Title } from '../Title';
import {
  AreaContainer,
  CheckboxStyled,
  Description,
  GridCheckbox,
  TagButton,
} from './ProductArea.styles';
import { t } from 'i18next';
import { onHandleKeyDown } from 'src/utils/onHandleKeyDown';

interface IArea {
  productArea: IProductArea;
  showDescription: () => void;
  selected: boolean;
  onSelect: (e: any) => void;
}

const Area = ({ showDescription, onSelect, selected, productArea }: IArea) => {
  const [checkedBox, setCheckedBox] = useState(selected);
  const areaRef = useRef<HTMLDivElement>(null);
  const tagButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setCheckedBox(selected);
  }, [selected]);

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = !checkedBox;
    setCheckedBox(newChecked);
    onSelect({ ...event, target: { ...event.target, checked: newChecked } });
  };

  const onHandleDown = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      onHandleChange(e);
    }
  };

  return (
    <AreaContainerRef
      ref={areaRef}
      component={'div'}
      container
      selected={selected}
      tabIndex={0}
      onKeyPress={showDescription}
    >
      <Grid alignItems="flex-start" component="header" container item>
        <Grid item xs={10}>
          <Title as={'h2'}>{productArea.name}</Title>
        </Grid>
        <GridCheckbox item xs={2}>
          <CheckboxStyled
            onChange={onHandleChange}
            onKeyPress={event => onHandleDown(event)}
            checked={checkedBox}
            tabIndex={0}
          />
        </GridCheckbox>
      </Grid>
      <Grid component="div" container item>
        <Description>{productArea.description}</Description>
      </Grid>
      <TagButton
        ref={tagButtonRef}
        type="button"
        role="button"
        onClick={showDescription}
        aria-label={t('LabelFullDescription')}
        onKeyDown={event => onHandleKeyDown(event, showDescription)}
        tabIndex={0}
      >
        {t('LabelFullDescription')}
      </TagButton>
    </AreaContainerRef>
  );
};

export default Area;

export const AreaContainerRef = React.forwardRef<HTMLDivElement, any>(
  (props, ref) => <AreaContainer {...props} innerRef={ref} />
);
