import React, { Component } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, CardLoader } from 'src/components';
import { EmptyArchiveMessage } from './EmptyArchiveMessage';
import { SigningCaseStatusApi } from 'src/models';
import { ISigningCaseListItem } from 'src/models/SigningCase';
import { default as ListActions } from 'src/state/SigningCasesList/actions';
import { getArchiveSigningCase } from 'src/state/SigningCasesList/selectors';
import {
  Filter,
  fromArchiveFilter,
  setDate,
  setStatus,
  setTimeframe,
} from './Filter';
import InfiniteList from './InfiniteList';
import { transformTimeframeStatus } from './services';
import * as fromRoot from '../state/rootReducer';
import { SigningCasesList } from 'src/AgreementsCard/SigningCasesList';

interface Props extends RouteComponentProps<{}> {
  fetchSigningCases: typeof ListActions.fetchSigningCases;
  goSigningCaseDetails: typeof ListActions.goSigningCaseDetails;
  resetSigningCase: typeof ListActions.resetSigningCase;
  signingCases: ReturnType<typeof getArchiveSigningCase>;
  date: Date;
  isDateValid: boolean;
  status: ReturnType<typeof fromArchiveFilter.getStatus>;
  timeframe: ReturnType<typeof fromArchiveFilter.getTimeframe>;
  setDate: typeof setDate;
  setStatus: typeof setStatus;
  setTimeframe: typeof setTimeframe;
}

const initialState = {
  hasNextPage: true,
  page: 1,
};

type State = Readonly<typeof initialState>;

export class ArchiveCard extends Component<Props, State> {
  state = initialState;
  _isMounted = false;
  status: SigningCaseStatusApi = 'Archived';

  componentDidMount() {
    this._isMounted = true;
    this.getSigningCases();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.resetFilters();
  }

  render() {
    const {
      status,
      timeframe,
      date,
      isDateValid,
      setDate,
      setStatus,
      setTimeframe,
      signingCases,
    } = this.props;
    const title = t('ArchivedAgreements');

    return (
      <Card
        labelText={title}
        title={title}
        popout={true}
        showNotifications={true}
        data-testid="archived-signing-cases-card"
      >
        <Filter
          date={date}
          isDateValid={isDateValid}
          status={status}
          timeframe={timeframe}
          setDate={setDate}
          setStatus={setStatus}
          setTimeframe={setTimeframe}
          applyFilter={this.applyFilter}
          date-testid="signing-case-filter"
        />
        <InfiniteList
          hasNextPage={signingCases.hasNextPage || false}
          loadNextPage={this.getSigningCases}
          isLoading={signingCases.isLoading}
        >
          {signingCases.list.length ? (
            <>
              <SigningCasesList
                documents={signingCases.list}
                type={'Archived'}
                onClick={this.onSigningCaseClick}
                data-testid="archived-signing-cases"
              />
              {signingCases.isLoading ? (
                <CardLoader data-testid="archive-loader" />
              ) : null}
            </>
          ) : signingCases.isLoading ? (
            <CardLoader />
          ) : (
            <EmptyArchiveMessage data-testid="empty-archive-msg" />
          )}
        </InfiniteList>
      </Card>
    );
  }

  private onSigningCaseClick = (
    signignCaseListItem: ISigningCaseListItem,
    index: number
  ) => {
    const { goSigningCaseDetails, history, location } = this.props;

    goSigningCaseDetails({
      history,
      index,
      location,
      signignCaseListItem,
      status: this.status,
    });
  };

  private getSigningCases = () => {
    if (!this.props.signingCases.isLoading && this._isMounted) {
      this.props.fetchSigningCases({
        dateTo: this.props.date,
        pageNo: this.state.page,
        pageSize: 10,
        status: transformTimeframeStatus(this.props.status),
      });
      this.incrementPage();
    }
  };

  private applyFilter = () => {
    this.props.resetSigningCase(this.status);
    this.resetPage(this.getSigningCases);
  };

  private resetFilters = () => {
    this.props.setStatus('All');
    this.props.setTimeframe('Today');
    this.props.resetSigningCase(this.status);
  };

  private incrementPage = () => this.setState(incrementPage);
  private resetPage = (cb?: () => void) =>
    this._isMounted && this.setState(resetPage, cb);
}

const resetPage = (prevState: State): State => ({
  ...prevState,
  page: initialState.page,
});

const incrementPage = (prevState: State): State => ({
  ...prevState,
  page: prevState.page + 1,
});

const ExtendedComponent = connect(
  state => ({
    date: fromRoot.getArchiveFilterDate(state),
    isDateValid: fromRoot.getArchiveFilterIsDateValid(state),
    status: fromRoot.getArchiveFilterStatus(state),
    timeframe: fromRoot.getArchiveFilterTimeframe(state),
    signingCases: getArchiveSigningCase(state),
  }),
  {
    fetchSigningCases: ListActions.fetchSigningCases,
    goSigningCaseDetails: ListActions.goSigningCaseDetails,
    resetSigningCase: ListActions.resetSigningCase,
    setDate,
    setTimeframe,
    setStatus,
  }
)(withRouter(ArchiveCard));
export { ExtendedComponent as AgreementsArchiveCard };
