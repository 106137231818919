import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { expirationDate as expirationDateActions } from 'src/state/Mandates/New';
import * as fromRoot from 'src/state/rootReducer';
import MandateValidTill from '../MandateValidTill';
import { FormControlStyled, Title } from './MandateExpirationDateInput.styles';
import { onHandleKeyDown } from 'src/utils/onHandleKeyDown';

export interface Props extends WithNamespaces {
  selectExpirationDate: typeof expirationDateActions.select;
  disableExpirationDate: typeof expirationDateActions.disable;
  date: ReturnType<typeof fromRoot.getNewMandateExpirationDate>;
}

export enum MandateValidity {
  ValidTillRevoked = 'ValidTillRevoked',
  ValidTill = 'ValidTill',
}

interface State {
  mandateValidTill: MandateValidity;
}

export const initializeMandateValidity = (date: Date) =>
  date ? MandateValidity.ValidTill : MandateValidity.ValidTillRevoked;

export class MandateExpirationDateInput extends Component<
  Props,
  Readonly<State>
> {
  readonly state = {
    mandateValidTill: initializeMandateValidity(this.props.date),
  };

  private onDateChange = date => {
    this.setState({ mandateValidTill: MandateValidity.ValidTill });
    this.props.selectExpirationDate(date);
  };

  private onValidTillRevoked = () => {
    this.setState({ mandateValidTill: MandateValidity.ValidTillRevoked });
    this.props.disableExpirationDate();
  };

  public render() {
    const { t, selectExpirationDate } = this.props;

    return (
      <FormControlStyled>
        <Title as="h2">{t('MandateValidTill')}</Title>
        <RadioGroup role="radiogroup">
          <FormControlLabel
            value={MandateValidity.ValidTillRevoked}
            onChange={this.onValidTillRevoked}
            control={
              <Radio
                onKeyDown={event =>
                  onHandleKeyDown(event, this.onValidTillRevoked)
                }
                checked={
                  this.state.mandateValidTill ===
                  MandateValidity.ValidTillRevoked
                }
                inputProps={{ role: 'radio' }}
              />
            }
            label={t('ValidTillRevoked')}
          />
          <FormControlLabel
            value={MandateValidity.ValidTill}
            onChange={this.onDateChange.bind(this, this.props.date)}
            control={
              <Radio
                onKeyDown={event =>
                  onHandleKeyDown(
                    event,
                    this.onDateChange.bind(this, this.props.date)
                  )
                }
                checked={
                  this.state.mandateValidTill === MandateValidity.ValidTill
                }
                inputProps={{ role: 'radio' }}
              />
            }
            label={
              <MandateValidTill
                dayPickerProps={{
                  date: this.props.date || '',
                  dayPickerProps: {
                    disabled: [
                      {
                        before: new Date(Date.now()),
                      },
                    ],
                    toMonth: null,
                  },
                  inputProps: {
                    disabled: isValidTillRevoked(this.state.mandateValidTill),
                    'aria-label': t('MandateExpirationDatePicker'),
                  },
                  onClick: selectExpirationDate,
                  label: '',
                }}
              />
            }
          />
        </RadioGroup>
      </FormControlStyled>
    );
  }
}

export const isValidTillRevoked = (x: MandateValidity) =>
  x === MandateValidity.ValidTillRevoked;

export const isValidTillDate = (x: MandateValidity) =>
  x === MandateValidity.ValidTill;

const connectedComponent = connect(
  state => ({
    date: fromRoot.getNewMandateExpirationDate(state),
  }),
  {
    selectExpirationDate: expirationDateActions.select,
    disableExpirationDate: expirationDateActions.disable,
  }
)(MandateExpirationDateInput);
export default withNamespaces()(connectedComponent);
