import React from 'react';
import { ISigningCaseListItem } from 'src/models';
import { SigningCasesListItem } from './SigningCasesListItem';
import { Table, TableHead, TableHeaderCell } from 'src/components';
import { t } from 'i18next';

type ListType = 'Archived' | 'NotSigned' | 'Waiting';

interface Props {
  documents: ISigningCaseListItem[];
  type?: ListType;
  onClick?: (document: ISigningCaseListItem, index: number) => void;
}

export const SigningCasesList = ({ documents, type, onClick }: Props) => {
  const handleClick = (index: number, document: ISigningCaseListItem) => {
    if (onClick) onClick(document, index);
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent,
    document: ISigningCaseListItem
  ) => {
    if ((e.keyCode === 13 || e.keyCode === 32) && onClick) {
      onClick(document, index);
    }
  };

  return (
    <Table data-testid="data-testid">
      <TableHead>
        <tr>
          <TableHeaderCell>{t('Title')}</TableHeaderCell>
          <TableHeaderCell>
            {type === 'Archived' ? t('DateArchived') : t('DeadlineForSigning')}
          </TableHeaderCell>
          <TableHeaderCell>{t('Status')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>
        {documents.map((document, index) => (
          <SigningCasesListItem
            key={index}
            document={document}
            onClick={() => handleClick(index, document)}
            onKeyDown={e => handleKeyDown(index, e, document)}
          />
        ))}
      </tbody>
    </Table>
  );
};
