import React from 'react';
import { Grid } from '@material-ui/core';
import { PortalButton } from 'src/components/Buttons';
import { Config } from 'src/state';
import { t } from 'i18next';
import { onHandleKeyDown } from 'src/utils/onHandleKeyDown';

interface IFooter {
  onConfirm?: () => void;
  onCancel?: () => void;
}

const Footer = ({ onConfirm, onCancel }: IFooter) => {
  const rejectButtonType = Config.isRealkreditDenmark()
    ? 'primary'
    : 'secondary';

  return (
    <Grid container wrap="wrap" spacing={16}>
      <Grid item xs={12} sm={6}>
        <PortalButton
          onClick={onConfirm}
          fullWidth
          onKeyDown={e => onHandleKeyDown(e, onConfirm)}
          aria-label={t('ContinueSession')}
        >
          {t('SessionContinueButton')}
        </PortalButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PortalButton
          onClick={onCancel}
          colorType={rejectButtonType}
          fullWidth
          onKeyDown={e => onHandleKeyDown(e, onCancel)}
          aria-label={t('LogOffEndSession')}
        >
          {t('LogOff')}
        </PortalButton>
      </Grid>
    </Grid>
  );
};

export default Footer;
