import React, { ChangeEvent, Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { TRootState } from 'src/state';
import { fetchIdentity } from 'src/state/Identity';
import { NewSigningMandate } from 'src/state/Mandates/';
import * as fromRoot from 'src/state/rootReducer';
import { Input } from 'src/components/Input';

const selectCompany = NewSigningMandate.selectCompany;

interface ICompanyFormGroup extends WithNamespaces {
  companyId: ReturnType<typeof fromRoot.getSelectedCompanyId>;
  companyRoles: ReturnType<typeof fromRoot.getCompanyRoles>;
  fetchData: typeof fetchIdentity.getIdentity;
  selectCompany: typeof selectCompany.set;
}

class CompanyFormGroup extends Component<ICompanyFormGroup> {
  componentDidMount() {
    if (!this.props.companyRoles) {
      this.props.fetchData();
    } else {
      this.props.selectCompany(this.props.companyRoles![0].companyId);
    }
  }

  render() {
    const { companyId, companyRoles, t } = this.props;

    if (!companyRoles) {
      return null;
    }

    return (
      <FormGroup>
        <Input
          labelText={t('MandateSelectCompany')}
          inputProps={{
            name: 'company',
            onChange: this.selectCompany.bind(this),
            value: companyId,
            type: 'select',
          }}
        >
          {companyRoles.map((role, i) => (
            <option key={i} value={role.companyId}>
              {`${role.companyName} [${role.companyId}]`}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }

  private selectCompany = ({
    currentTarget: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    this.props.selectCompany(value);
  };
}

const mapStateToProps = (state: TRootState) => ({
  companyId: fromRoot.getSelectedCompanyId(state),
  companyRoles: fromRoot.getCompanyRoles(state),
});

const ConnectedComponent = connect(
  mapStateToProps,
  {
    fetchData: fetchIdentity.getIdentity,
    selectCompany: selectCompany.set,
  }
)(CompanyFormGroup);
export default withNamespaces()(ConnectedComponent);
