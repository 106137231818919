import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { logOut as logOutAction } from 'src/state/UserSession';
import {
  LogoutButton,
  LogoutIcon,
  LogoutText,
} from './PortalLogoutButton.styles';

interface IProps {
  logOut: typeof logOutAction;
}

const PortalLogoutButton = ({ logOut }: IProps) => {
  const buttonText = t('LogOut');
  return (
    <LogoutButton onClick={logOut}>
      <LogoutText>{buttonText}</LogoutText>
      <LogoutIcon type="logout" aria-label={buttonText} />
    </LogoutButton>
  );
};

export default connect(
  null,
  {
    logOut: logOutAction,
  }
)(PortalLogoutButton);
