import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { TNavigationLinks } from 'src/models/NavigationLink';
import { ThemeProvider } from 'styled-components';
import { darkBlue } from '../../styles/colors';
import DropdownMenu from '../DropdownMenu';
import HamburgerButton from './HamburgerButton';
import { HamburgerMenu } from './HamburgerMenu.styles';
import { withTheme, DefaultTheme } from 'styled-components';
import { LanguageMenuLink } from 'src/routes';
import { t } from 'i18next';

interface Props extends HTMLAttributes<HTMLElement> {
  links: TNavigationLinks;
  awaitingSigningCasesCount: number;
  theme: DefaultTheme;
  languageCode: string;
  languageMenuLinks: LanguageMenuLink[];
  children?: ReactNode;
}

interface ITheme {
  backgroundColor: string;
  color: string;
}

interface IThemeProvider {
  dark: ITheme;
  light: ITheme;
}

interface InitialState {
  isHamburgerMenuOpen: boolean;
  selectedTheme: keyof IThemeProvider;
  theme: IThemeProvider;
}

const getInitialState = (theme: any): InitialState => {
  return {
    isHamburgerMenuOpen: false,
    selectedTheme: 'light',
    theme: {
      dark: {
        backgroundColor: theme.pallete!.backgrounds.hamburgerMenu,
        color: 'white',
      },
      light: {
        backgroundColor: theme.pallete!.backgrounds.header,
        color: darkBlue,
      },
    },
  };
};

const PortalHamburgerMenu = ({
  theme,
  awaitingSigningCasesCount,
  links,
  languageCode,
  languageMenuLinks,
  ...rest
}: Props) => {
  const [state, setState] = useState(() => getInitialState(theme));

  const toggleHamburgerMenu = () => {
    setState(prevState => ({
      ...prevState,
      isHamburgerMenuOpen: !prevState.isHamburgerMenuOpen,
      selectedTheme: prevState.selectedTheme === 'light' ? 'dark' : 'light',
    }));
  };

  return (
    <HamburgerMenu
      isOpen={state.isHamburgerMenuOpen}
      toggle={toggleHamburgerMenu}
      {...rest}
    >
      <ThemeProvider theme={state.theme[state.selectedTheme]}>
        <HamburgerButton
          awaitingSigningCasesCount={awaitingSigningCasesCount}
          isOpen={state.isHamburgerMenuOpen}
          aria-label={t('MainNavigationButtonLabel')}
        />
      </ThemeProvider>
      <DropdownMenu
        links={links}
        isHamburgerMenuOpen={state.isHamburgerMenuOpen}
        toggleHamburgerMenu={toggleHamburgerMenu}
        awaitingSigningCasesCount={awaitingSigningCasesCount}
        languageCode={languageCode}
        languageMenuLinks={languageMenuLinks}
        data-testid="navigation-mobile"
      />
    </HamburgerMenu>
  );
};

export default withTheme(PortalHamburgerMenu);
